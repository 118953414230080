import React from "react";
import MainContent from "./MainContent";
import SecondContent from "./SecondContent";
import ThirdContent from "./ThirdContent";
const Homepage = () => {
  return (
    <>
      <MainContent />
      <SecondContent />
      <ThirdContent />
    </>
  );
};
export default Homepage;
